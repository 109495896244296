<template>
  <v-data-table
    :headers="headers"
    :items="data"
    :loading="tableLoading"
    class="elevation-1"
  >
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="edit(item)"> mdi-pencil </v-icon>
      <v-icon small @click="remove(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:item.tipo="{ item }">
      {{ getTipo(item.tipo) }}
    </template>
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Matriz SWOT</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>

        <v-dialog v-model="dialog" max-width="600px" @click:outside="close">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"
              >Novo</v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Matriz SWOT</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-row>
                      <v-text-field
                        v-model="Item.descricao"
                        label="Descrição"
                        :error-messages="errors['descricao']"
                      ></v-text-field>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-select
                          :items="tipos"
                          v-model="Item.tipo"
                          item-text="text"
                          item-value="value"
                          :error-messages="errors['tipo']"
                          label="Tipo"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="darken-1" text @click="close">Cancelar</v-btn>
              <v-btn :loading="saving" color="darken-1" text @click="save"
                >Salvar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
  </v-data-table>
</template>

<script>
import { Swot } from "@/api/eqpdti";
export default {
  name: "Swot",
  data() {
    return {
      dialog: false,
      saving: false,
      tableLoading: false,
      errors: [],
      tipos: [
        {
          value: 1,
          text: "Força",
        },
        {
          value: 2,
          text: "Fraqueza",
        },
        {
          value: 3,
          text: "Ameaça",
        },
        {
          value: 4,
          text: "Oportunidade",
        },
      ],
      data: [],
      Item: {},
      headers: [
        {
          text: "Descrição",
          value: "descricao",
          align: "center",
        },
        {
          text: "Tipo",
          value: "tipo",
          align: "center",
        },
        {
          text: "Ações",
          value: "actions",
          sortable: false,
          align: "end",
        },
      ],
    };
  },

  methods: {
    getTipo(id) {
      let f = this.tipos.filter((i) => i.value == id);
      if (f.length > 0) {
        return f[0].text;
      }
      return "";
    },
    remove(item) {
      if (window.confirm("Deseja realmente excluir?")) {
        this.tableLoading = true;
        Swot.remove(item["id"]).then(() => {
          this.tableLoading = false;
          this.fetch();
        });
      }
    },
    edit(item) {
      this.Item = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.Item = {};
      });
    },
    save() {
      this.saving = true;
      const _then = () => {
        this.saving = false;
        this.close();
        this.fetch();
      };
      const _catch = (error) => {
        this.saving = false;
        this.errors = error.response.data;
        if (this.errors["non_field_errors"]) {
          this.$emitter.emit("alert:error", this.errors["non_field_errors"][0]);
        } else {
          this.$emitter.emit("alert:error", this.errors);
        }
      };

      if (this.Item["id"]) {
        let id = this.Item["id"];

        Swot.update(id, this.Item).then(_then).catch(_catch);
      } else {
        this.Item = Object.assign(this.Item, {});
        Swot.post(this.Item).then(_then).catch(_catch);
      }
    },
    fetch() {
      this.tableLoading = true;
      Swot.list({}).then((response) => {
        this.tableLoading = false;
        this.data = response.data;
      });
    },
  },
  created() {
    this.fetch();
  },
};
</script>
